<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <TTTextField
          :value="value.question"
          large
          label="Вопрос"
          placeholder="Сформулируйте вопрос"
          :error="validation('question').status"
          :error-messages="validation('question').value"
          data-test-label="content-rate-form-question"
          @input="v => handleChange('question',v)"
        />
        <VCheckbox
          :ripple="false"
          :input-value="value.has_comment"
          :error="validation('has_comment').status"
          :error-messages="validation('has_comment').value"
          label="Разрешить добавлять комментарии"
          hide-details="auto"
          class="mt-6 pt-0"
          color="tt-black"
          data-test-label="content-rate-form-has_comment"
          @change="v => handleChange('has_comment',v)"
        />
        <VCheckbox
          :ripple="false"
          :input-value="value.is_anonymous"
          :error="validation('is_anonymous').status"
          :error-messages="validation('is_anonymous').value"
          label="Может быть анонимным"
          hide-details="auto"
          class="mt-6 pt-0"
          color="tt-black"
          data-test-label="content-rate-form-is_anonymous"
          @change="v => handleChange('is_anonymous',v)"
        />
      </VCol>
      <VCol cols="6">
        <TTSelect
          :value="typeMax"
          :items="typeList"
          large
          label="Тип шкалы"
          data-test-label="content-rate-form-type"
          @change="v => handleTypeChange(v)"
        />
      </VCol>
      <VCol cols="6">
        <VRow>
          <VCol
            v-for="(item, i) in list"
            :key="i"
            cols="12"
          >
            <TTTextField
              :value="list[i]"
              large
              :label="rateFieldTitle(i)"
              placeholder="Введите описание"
              :error="validation(`action.text_${i}`).status"
              :error-messages="validation(`action.text_${i}`).value"
              :data-test-label="`content-rate-form-description-${i}`"
              @input="v => handleTextChange(i, v)"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="12"
        class="pt-6 pb-0"
      >
        <VDivider />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';

const lists = {
  'smile:5': [
    '', '', '', '', '',
  ],
  'digit:5': [
    'Нет', 'Скорее нет', 'Затрудняюсь ответить', 'Скорее да', 'Да',
  ],
  'digit:6': [
    'Категорически не согласен',
    'Не согласен',
    'Скорее не согласен',
    'Скорее согласен',
    'Согласен',
    'Полностью согласен',
  ],
  'digit:10': [
    '', '', '', '', '', '', '', '', '', '',
  ],
};

const defaultType = 'smile:5';

export default {
  name: 'ContentRateForm',
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    const typeList = [
      { text: 'Смайлы (1-5)', value: 'smile:5' },
      { text: 'Шкала из 5 цифр', value: 'digit:5' },
      { text: 'Шкала из 6 цифр', value: 'digit:6' },
    ];
    if (this.$di.ff.get('show_select_rate_10')) {
      typeList.push({ text: 'Шкала из 10 цифр', value: 'digit:10' });
    }
    return {
      defaultValue: {
        typeMax: defaultType, // служебное свойство
        question: '',
        has_comment: false,
        is_anonymous: false,
        action: {
          text: lists[defaultType].slice(),
        },
        validate: {
          max: 0,
        },
      },
      typeMax: defaultType,
      typeList,
      list: lists[defaultType].slice(),
    };
  },
  watch: {
    list: {
      handler() {
        this.handleChange('action.text', this.list);
      },
      deep: true,
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    } else {
      this.typeMax = `${this.value.type}:${this.value.validate.max}`;
      this.list = this.value.action.text.slice();
    }
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue).forEach((key) => {
        this.handleChange(key, this.defaultValue[key]);
      });
    },
    rateFieldTitle(i) {
      const text = `${i + 1} оценка`;
      if (i === 0) return `${text} (отрицательная)`;
      if (i === this.list.length - 1) return `${text} (положительная)`;
      return text;
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
    handleTextChange(index, value) {
      const target = `action.text_${index}`;
      this.list[index] = value;
      this.handleChange('action.text', this.list, target);
    },
    handleTypeChange(val) {
      this.list = lists[val].slice();
      this.handleChange('typeMax', val);
      this.list.forEach((value, index) => {
        this.handleTextChange(index, value);
      });
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
