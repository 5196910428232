<template>
  <VContainer
    fluid
  >
    <VRow>
      <VCol>
        <div
          class="breadcrumbs"
          data-test="content-list-breadcrumbs"
        >
          <TTBreadcrumbs :items="breadcrumbs" />
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol
        cols="8"
        class="d-flex align-center"
      >
        <h1
          class="tt-text-headline-1"
          data-test="task-name"
        >
          {{ task.name }}
        </h1>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTDataTable
          ref="sortableTable"
          :headers="headers"
          :items="taskContent"
          data-test="content-list-task-content"
          hide-default-footer
          :items-per-page="taskContent.length"
        >
          <template #item="{item, index}">
            <tr
              :key="itemKey(item)"
              class="sortableRow"
              :data-test="`content-list-row-item${itemKey(item)}`"
            >
              <td
                class="sortHandle"
                data-test="content-list-sort"
              >
                <VIcon
                  color="tt-black"
                  size="19"
                >
                  fal fa-grip-lines
                </VIcon>
              </td>
              <td data-test="content-list-number">
                {{ index + 1 }}
              </td>
              <td
                valign="top"
                data-test="content-list-type"
              >
                <div
                  data-test="task-type"
                  class="text--black py-4"
                >
                  {{ contentTypeTextMap[item.type] }}
                </div>
              </td>
              <td
                style="word-break: break-word;min-width: 396px;"
                data-test="content-list-component"
              >
                <Component
                  :is="componentsMap[item.type]"
                  :data="item"
                  class="py-4"
                />
              </td>
              <td data-test="task-bonus">
                <div class="d-flex align-start justify-space-between">
                  <div
                    v-if="item.bonus"
                    data-test="content-list-bonus"
                  >
                    {{ item.bonus }}
                    <span v-if="item.bonus_wrong">/{{ item.bonus_wrong }}</span>
                  </div>
                  <div
                    v-else
                    data-test="content-list-bonus-zero"
                  >
                    –
                  </div>
                  <VMenu
                    content-class="v-menu-shadow"
                    offset-y
                    left
                    min-width="250px"
                    max-width="250px"
                    nudge-right="16px"
                  >
                    <template #activator="{ on }">
                      <TTBtn
                        class="table-menu-button"
                        fab
                        small
                        depressed
                        color="transparent tt-ghost--text"
                        data-test="button-send-invite"
                        v-on="on"
                      >
                        <VIcon size="19">
                          fal fa-ellipsis-h
                        </VIcon>
                      </TTBtn>
                    </template>
                    <VCard class="v-menu-card">
                      <VList dense>
                        <VListItem
                          class="custom-menu-item"
                          data-test="content-list-menu-edit"
                          @click="openModal('showTaskContentForm', item)"
                        >
                          <VListItemIcon class="mr-4 ml-0">
                            <VIcon
                              size="19"
                              color="tt-black"
                            >
                              fal fa-pencil
                            </VIcon>
                          </VListItemIcon>
                          <VListItemContent>Редактировать</VListItemContent>
                        </VListItem>
                        <VListItem
                          class="custom-menu-item"
                          data-test="content-list-menu-delete"
                          @click="openDelete(item)"
                        >
                          <VListItemIcon class="mr-4 ml-0">
                            <VIcon
                              size="19"
                              color="error"
                            >
                              fal fa-trash-alt
                            </VIcon>
                          </VListItemIcon>
                          <VListItemContent class="error--text">
                            Удалить
                          </VListItemContent>
                        </VListItem>
                      </VList>
                    </VCard>
                  </VMenu>
                </div>
              </td>
            </tr>
          </template>
        </TTDataTable>
      </VCol>
    </VRow>
    <DialogWrapper
      v-model="showSearchBar"
      width="100%"
      transparent
      content-class="ma-0 elevation-0 align-self-start mt-3 search-dialog"
    >
      <div
        class="search-dialog__content"
      >
        <VContainer class="pl-4">
          <VRow>
            <VCol
              cols="12"
              md="10"
              :offset="$vuetify.breakpoint.mdAndDown? 0 :1"
            >
              <VContainer
                fluid
              >
                <VRow>
                  <VCol cols="12">
                    <SearchBar
                      v-if="showSearchBar"
                      to-search-page
                    />
                  </VCol>
                </VRow>
              </VContainer>
            </VCol>
          </VRow>
        </VContainer>
      </div>
    </DialogWrapper>
    <DialogWrapper
      id="dialogWrapper"
      v-model="showTaskContentForm"
      fullscreen
      attach
      @click:outside="closeModal('showTaskContentForm')"
    >
      <TaskContentForm
        v-if="showTaskContentForm"
        :edit="edit"
        :edit-entity="activeEntity"
        :location="location"
        :level-n="level ? level.n : undefined"
        :task="task"
        @close="closeModal('showTaskContentForm')"
        @save="handleSave('showTaskContentForm')"
      />
    </DialogWrapper>

    <DialogWrapper
      v-model="deleteInfoDialog"
      width="396"
      hide-overlay
      @click:outside="deleteInfoDialog = false"
    >
      <VRow>
        <VCol
          cols="12"
          class="pt-0 pb-2"
        >
          <h2 class="text--black">
            Для удаления контента необходимо убрать упоминания
          </h2>
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="deleteInfo.mailing"
          cols="12"
          class="py-0"
        >
          Контент упоминается в событиях:
        </VCol>
      </VRow>
      <VRow>
        <VCol class="pb-0">
          <VDivider />
        </VCol>
      </VRow>
      <VRow>
        <VCol
          v-if="deleteInfo.mailing"
          cosl="12"
          class="py-0"
        >
          <VList
            class="pa-0 overflow-auto"
            max-height="350px"
            data-test="content-list-delete-form-events"
          >
            <!-- Хз куда это приведет... -->
            <VListItem
              v-for="item in deleteInfo.mailing"
              :key="item.id"
              dense
              class="pa-3 striped-list"
              :to="`/event#location=${currentLocationId}&mailing=${item.id}`"
            >
              <VListItemContent class="ma-0 pa-0">
                {{ item.name }}
              </VListItemContent>
              <VListItemIcon class="mr-0">
                <VIcon
                  size="19"
                  color="tt-black"
                >
                  fal fa-arrow-to-right
                </VIcon>
              </VListItemIcon>
            </VListItem>
          </VList>
        </VCol>
        <TTBtn
          absolute
          top
          right
          width="20"
          height="20"
          min-width="20"
          class="pa-0"
          depressed
          color="transparent"
          data-test="content-list-delete-form-close-button"
          @click="deleteInfoDialog = false"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
      </VRow>
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteDialog = false"
    >
      <DeleteForm
        v-if="toDeleteId"
        @delete="handleDelete"
        @cancel="deleteDialog = false"
      >
        <h3 class="delete-form__title">
          Удалить контент?
        </h3>
        <p>
          После удаления контент будет недоступен для восстановления и просмотра в приложении.
        </p>
      </DeleteForm>
    </DialogWrapper>
    <DialogWrapper
      v-model="deleteConfirmVideoDialog"
      width="396"
      content-class="tt-card"
      hide-overlay
      @click:outside="deleteConfirmVideoDialog = false"
    >
      <DeleteForm
        button-cancel="Нет"
        button-confirm="Да"
        @delete="confirmDelete(deleteItem)"
        @cancel="deleteConfirmVideoDialog = false"
      >
        <p>
          Все загруженные материалы из данного элемента будут удалены. Продолжить?
        </p>
      </DeleteForm>
    </DialogWrapper>
  </VContainer>
</template>

<script>
import Sortable from 'sortablejs';
import * as snamiApi from '@/services/backend/snami';
import ContentHtml from '@/components/content/ContentHtml.vue';
import ContentImage from '@/components/content/ContentImage.vue';
import ContentDocument from '@/components/content/ContentDocument.vue';
import ContentQrCode from '@/components/content/ContentQrCode.vue';
import ContentVideo from '@/components/content/ContentVideo.vue';
import ContentMap from '@/components/content/ContentMap.vue';
import ContentEnterString from '@/components/content/ContentEnterString.vue';
import ContentAddPhoto from '@/components/content/ContentAddPhoto.vue';
import ContentYesNo from '@/components/content/ContentYesNo.vue';
import ContentOneWord from '@/components/content/ContentOneWord.vue';
import ContentSelectOne from '@/components/content/ContentSelectOne.vue';
import ContentSelectMany from '@/components/content/ContentSelectMany.vue';
import ContentVote from '@/components/content/ContentVote.vue';
import ContentRate from '@/components/content/ContentRate.vue';
import ContentOffer from '@/components/content/ContentOffer.vue';
import ContentContact from '@/components/content/ContentContact.vue';

import TaskContentForm from '@/components/forms/TaskContentForm.vue';
import DeleteForm from '@/components/forms/DeleteForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import {
  R_CONTENT_SEARCH, R_EVENTS_ID, R_LEVEL_ID, R_TASK_LEVEL,
} from '@/plugins/vue-router';
import SearchBar from '@/components/shared/SearchBar.vue';

export default {
  name: 'ContentList',
  components: {
    SearchBar,
    DialogWrapper,
    ContentHtml,
    ContentImage,
    ContentDocument,
    ContentQrCode,
    ContentVideo,
    ContentMap,
    ContentEnterString,
    ContentAddPhoto,
    ContentYesNo,
    ContentOneWord,
    ContentSelectOne,
    ContentSelectMany,
    ContentVote,
    ContentRate,
    ContentOffer,
    ContentContact,
    TaskContentForm,
    DeleteForm,
  },
  data() {
    return {
      showTaskContentForm: false,
      deleteDialog: false,
      edit: false,
      toDeleteId: 0,
      deleteItem: {},
      deleteInfo: {},
      deleteInfoDialog: false,
      deleteConfirmVideoDialog: false,
      activeEntity: {},
      wasSaved: false,
      headers: [
        {
          text: '',
          value: 'drag',
          sortable: false,
          width: '60px',
        },
        {
          text: '№',
          value: 'n',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Тип',
          value: 'type',
          sortable: false,
          width: '140px',
        },
        {
          text: 'Контент',
          value: 'content',
          sortable: false,
        },
        {
          text: 'Бонусы',
          value: 'bonus',
          sortable: false,
          width: '135px',
        },
      ],
      task: {},
      taskType: null,
      taskContent: [],
      breadcrumbs: [],
      level: {},
      event: {},
      editable: false,
      location: {},
      dataLoading: false,
      componentsMap: {
        HTML: 'ContentHtml',
        IMAGE: 'ContentImage',
        DOCUMENT: 'ContentDocument',
        QR_CODE: 'ContentQrCode',
        VIDEO: 'ContentVideo',
        MAP: 'ContentMap',
        ENTER_STRING: 'ContentEnterString',
        ADD_PHOTO: 'ContentAddPhoto',
        YES_NO: 'ContentYesNo',
        ONE_WORD: 'ContentOneWord',
        SELECT_ONE: 'ContentSelectOne',
        SELECT_MANY: 'ContentSelectMany',
        VOTE: 'ContentVote',
        RATE: 'ContentRate',
        OFFER: 'ContentOffer',
        CONTACT: 'ContentContact',
      },
      contentTypeTextMap: {
        HTML: 'Блок HTML',
        IMAGE: 'Изображение',
        DOCUMENT: 'Документ',
        QR_CODE: 'QR-код',
        VIDEO: 'Видео',
        MAP: 'Карта',
        ENTER_STRING: 'Ввод строки',
        ADD_PHOTO: 'Сделать фото',
        YES_NO: 'Вопрос да/нет',
        ONE_WORD: 'Кодовое слово',
        SELECT_ONE: 'Вопрос с одним ответом',
        SELECT_MANY: 'Вопрос с несколькими ответами',
        VOTE: 'Голосование',
        RATE: 'Оценить по шкале',
        OFFER: 'Предложение о работе',
        CONTACT: 'Контакт сотрудника',
      },
      // Sortable
      itemKeys: new WeakMap(),
      currentItemKey: 0,
      showSearchBar: false,
    };
  },
  computed: {
    contentId() {
      return this.$route.params.id;
    },
    selectedContentForDelete() {
      return this.taskContent.find((task) => task.id === this.toDeleteId);
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.hash) {
          if (this.$route.hash.includes('#openForm')) {
            this.showTaskContentForm = true;
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    this.getData();
  },
  mounted() {
    Sortable.create(
      this.$refs.sortableTable.$el.getElementsByTagName('tbody')[0],
      {
        draggable: '.sortableRow',
        handle: '.sortHandle',
        onEnd: this.dragReorder,
      },
    );
    this.$root.$on('showTaskContentForm', this.createTaskContent);
    this.$root.$on('onClickShowSearchBar', this.toggleSearchBar);
  },
  beforeDestroy() {
    this.$root.$off('showTaskContentForm', this.createTaskContent);
    this.$root.$on('onClickShowSearchBar', this.toggleSearchBar);
  },
  methods: {
    handleChange(value) {
      this.$router.push({
        name: R_CONTENT_SEARCH,
        query: { search: value },
      });
    },
    toggleSearchBar() {
      this.showSearchBar = !this.showSearchBar;
    },
    createTaskContent() {
      this.openModal('showTaskContentForm');
    },
    async dragReorder({
      oldIndex,
      newIndex,
    }) {
      try {
        const movedItem = this.taskContent.splice(oldIndex, 1)[0];
        this.taskContent.splice(newIndex, 0, movedItem);

        const data = {
          task_id: this.task.id,
          task_content_ids: this.taskContent.map((item) => item.id),
        };
        await this.$repositories.taskContent.reorder({ data });
      } catch (e) {
        console.warn(e);
      }
    },
    itemKey(item) {
      if (!this.itemKeys.has(item)) {
        this.currentItemKey += 1;
        this.itemKeys.set(item, this.currentItemKey);
      }
      return this.itemKeys.get(item);
    },

    async getData() {
      this.dataLoading = true;
      let res;
      let level;
      let location;
      let event;
      let task;
      try {
        res = await this.$repositories.task.list({ data: { filter: { id: this.contentId } } });
        [task] = res.data.data;
        this.task = task;
        if (this.task.level_id) {
          this.taskType = 'level';
          if (this.task.level_id > 0) {
            res = await snamiApi.getLevel({ filter: { id: this.task.level_id } });
            [level] = res.data.data;
            this.level = level;
          }
        } else {
          this.taskType = 'event';
          if (this.task.id > 0) {
            res = await this.$repositories.event.list({ data: { filter: { task_id: this.task.id } } });
            [event] = res.data.data;
            this.event = event;
            this.editable = true;
          }
        }
        res = await this.$repositories.location.list({ filter: { id: this.task.location_id } });
        [location] = res.data.data;
        this.location = location;
        const crumbs = [];
        if (this.taskType === 'level') {
          crumbs.push({
            text: location.is_active ? location.name : `Тестовая • ${location.name}`,
            to: {
              name: R_LEVEL_ID,
              params: { id: location.id },
            },
          });
          const levelName = [level.name];
          if (level.for_tutor) {
            levelName.push('(Наставничество)');
          }
          crumbs.push({
            text: levelName.join(' '),
            to: {
              name: R_TASK_LEVEL,
              params: { id: level.id },
            },
          });
          crumbs.push({
            text: task.name,
            disabled: true,
          });
        } else {
          crumbs.push({
            text: location.is_active ? location.name : `Тестовая • ${location.name}`,
            to: {
              name: R_EVENTS_ID,
              params: { locationId: location.id },
            },
          });
          crumbs.push({
            text: event.name,
            disabled: true,
          });
        }
        this.breadcrumbs = crumbs;
        res = await this.$repositories.taskContent.list({ data: { filter: { task_id: [this.task.id] } } });
        const { data } = res.data;
        this.taskContent = data;
      } catch (e) {
        console.warn(e);
      }
      this.dataLoading = false;
    },
    handleSave(name) {
      this.wasSaved = true;
      // закрыть окно
      this.closeModal(name);
      // всякие другие действия
      this.getData();
    },
    openModal(name, item) {
      this[name] = true;
      if (item) {
        this.activeEntity = item;
        this.edit = true;
      }
    },
    closeModal(name) {
      this.$root.$emit('modalTaskContentFormWillClose', {
        wasSaved: this.wasSaved,
      });
      this[name] = false;
      this.edit = false;
      this.activeEntity = {};
    },
    openDelete(item) {
      if (item.type === 'VIDEO') {
        this.deleteItem = item;
        this.deleteConfirmVideoDialog = true;
      } else {
        this.checkConnections(item);
      }
    },
    checkConnections(item) {
      snamiApi.getContentConnections({
        entity: 'TASK_CONTENT',
        id: item.id,
      })
        .then(({ data }) => {
          if (data.data.has_connection) {
            this.deleteInfo = data.data;
            this.deleteInfoDialog = true;
          } else {
            this.deleteDialog = true;
            this.toDeleteId = item.id;
          }
        });
    },
    confirmDelete(item) {
      this.deleteConfirmVideoDialog = false;
      this.checkConnections(item);
    },
    async handleDelete() {
      try {
        await this.$repositories.taskContent.delete({ data: { id: this.toDeleteId } });
        this.deleteDialog = false;
        this.toDeleteId = 0;
        this.getData();
        if (this.selectedContentForDelete?.type === 'VIDEO') {
          const meta = JSON.parse(this.selectedContentForDelete?.meta);
          await snamiApi.videoDelete(meta?.url);
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
.sortHandle {
  cursor: move;
}
</style>
