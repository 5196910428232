<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow class="py-0">
      <VCol cols="12">
        <TTTextField
          large
          label="Вопрос"
          :value="value.question"
          placeholder="Введите вопрос"
          :error="validation('question').status"
          :error-messages="validation('question').value"
          data-test-label="content-addphoto-form-question"
          @change="v => handleChange('question',v)"
        />
      </VCol>
      <VCol
        v-if="visionTemplateList.length"
        cols="12"
        class="pt-3"
      >
        <VCheckbox
          v-for="item in visionTemplateList"
          :key="item.value"
          :ripple="false"
          class="mt-0 pt-0"
          color="tt-black"
          hide-details="auto"
          :value="item.value"
          :label="item.text"
          :input-value="value.validate.vision_template"
          :error="validation('validate.vision_template').status"
          :error-messages="validation('validate.vision_template').value"
          :data-test="`content-addphoto-form-vision_template-${item.value}`"
          @change="v => handleChange('validate.vision_template',v)"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validation, watchValidationStatus } from '@/services';
import { dictionaryKeys } from '@/constants';

export default {
  name: 'ContentAddPhotoForm',
  directives: { mask },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({
        type: null,
        id: 0,
      }),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        question: '',
        validate: {
          max_width: 0,
          max_height: 0,
          vision_template: '',
        },
      },
      loading: false,
      visionTemplateList: [],
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    }
    this.loading = true;
    this.$repositories.customer.getCustomerDictionary({
      data: { key: [dictionaryKeys.visionTemplate] },
    })
      .then((r) => {
        const { data } = r.data;
        this.visionTemplateList = data.vision_template
          .map((item) => ({
            value: item.name,
            text: `Использовать шаблон для анализа фото «${item.label}»`,
          }));
        this.loading = false;
      });
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue)
        .forEach((key) => {
          this.handleChange(key, this.defaultValue[key]);
        });
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
    // ***********************************************
  },
};

</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.handle {
  cursor: move;
}

</style>
