<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <TTTextField
          large
          label="Вопрос"
          placeholder="Введите вопрос"
          :value="value.question"
          :error="validation('question').status"
          :error-messages="validation('question').value"
          data-test-label="content-enterstring-form-question"
          @change="v => handleChange('question',v)"
        />
      </VCol>
      <VCol cols="6">
        <TTTextField
          v-mask="'####'"
          large
          label="Максимальная длина строки"
          placeholder="Введите максимальную длину строки"
          :value="value.validate.max || ''"
          :error="validation('validate.max').status"
          :error-messages="validation('validate.max').value"
          data-test-label="content-enterstring-form-validate-max"
          @change="v => handleChange('validate.max', parseInt(v, 10))"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { mask } from 'vue-the-mask';
import { validation, watchValidationStatus } from '@/services';

export default {
  name: 'ContentEnterStringForm',
  directives: { mask },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        question: '',
        validate: {
          min: 0,
          max: 1000,
        },
      },
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    }
  },
  mounted() {
    // fill quill after it appears in DOM
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue).forEach((key) => {
        this.handleChange(key, this.defaultValue[key]);
      });
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
