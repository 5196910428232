<template>
  <div
    class="html-content content-item"
    data-test="content-html"
    v-html="meta.content"
  />
</template>

<script>
export default {
  name: 'ContentHtml',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
  .v-application .html-content p {
    margin-bottom: 0;
  }
</style>
