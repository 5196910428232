<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <TTTextField
          large
          label="Вопрос"
          placeholder="Введите вопрос"
          :value="value.question"
          :error="validation('question').status"
          :error-messages="validation('question').value"
          data-test-label="content-oneword-form-question"
          @change="v => handleChange('question',v)"
        />
      </VCol>
      <VCol cols="12">
        <TTTextField
          large
          label="Сообщение при правильном ответе"
          placeholder="Введите сообщение"
          :value="value.action.right"
          :error="validation('action.right').status"
          :error-messages="validation('action.right').value"
          data-test-label="content-oneword-form-correct"
          @change="v => handleChange('action.right',v)"
        />
      </VCol>
      <VCol cols="12">
        <TTTextField
          large
          label="Сообщение при неправильном ответе"
          placeholder="Введите сообщение"
          :value="value.action.wrong"
          :error="validation('action.wrong').status"
          :error-messages="validation('action.wrong').value"
          data-test-label="content-oneword-form-incorrect"
          @change="v => handleChange('action.wrong',v)"
        />
      </VCol>
      <VCol
        cols="6"
        class="pb-0"
      >
        <TTTextField
          hint="Длина кодового слова – 5 букв"
          persistent-hint
          large
          label="Введите кодовое слово"
          placeholder="Введите кодовое слово"
          :counter="MAX_TEXT"
          :value="value.validate.text"
          :error="validation('validate.text').status"
          :error-messages="validation('validate.text').value"
          maxlength="5"
          data-test-label="content-oneword-form-word"
          @input="v => handleChange('validate.text',v.substr(0, 5))"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';

export default {
  name: 'ContentOneWordForm',
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      MAX_TEXT: 5,
      defaultValue: {
        question: '',
        validate: {
          text: '',
        },
        action: {
          right: '',
          wrong: '',
        },
      },
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    }
  },
  mounted() {
    // fill quill after it appears in DOM
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue)
        .forEach((key) => {
          this.handleChange(key, this.defaultValue[key]);
        });
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
