<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol
        cols="12"
      >
        <div class="text--black mb-3">
          Файл изображения
        </div>
        <UploadImage
          :value="value.file"
          :error="validation('file').status"
          :error-messages="validation('file').value"
          data-test="content-image-form-upload"
          @input="v => handleChange('file',v)"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';
import UploadImage from '@/components/ui/Uploader.vue';

export default {
  name: 'ContentImageForm',
  components: { UploadImage },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        file: '',
      },
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    }
  },
  mounted() {
    // fill quill after it appears in DOM
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue).forEach((key) => {
        this.handleChange(key, this.defaultValue[key]);
      });
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
