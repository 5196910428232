<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <TTTextField
          :value="value.filename"
          large
          label="Имя файла"
          placeholder="Введите имя файла"
          :error="validation('filename').status"
          :error-messages="validation('filename').value"
          data-test-label="content-document-form-name"
          @change="v => handleChange('filename',v)"
        />
      </VCol>
      <VCol>
        <TTTextarea
          :maxlength="10000"
          label="Описание"
          auto-grow
          row-height="14"
          placeholder="Введите текст"
          hide-details="auto"
          :value="value.description"
          :error="validation('description').status"
          :error-messages="validation('description').value"
          data-test-label="content-document-form-description"
          @input="v => handleChange('description',v)"
        />
      </VCol>
      <VCol
        cols="12"
      >
        <UploadFile
          :value="value.file"
          :error="validation('file').status"
          single-line
          :accept="accept"
          filled
          placeholder="Введите имя файла"
          :error-messages="validation('file').value"
          data-test="content-document-form-upload-file"
          @fileNameChanged="v => handleFileNameChange(v)"
          @input="v => handleChange('file',v)"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';
import { dictionaryKeys } from '@/constants';

import UploadFile from '@/components/ui/UploadFile.vue';

export default {
  name: 'ContentDocumentForm',
  components: {
    UploadFile,
  },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        file: '',
        filename: '',
        description: '',
      },
      files: [],
      accept: '',
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.$repositories.customer.getCustomerDictionary({
      data: {
        key: [dictionaryKeys.fileType],
      },
    })
      .then((r) => {
        const { data } = r.data;
        this.accept = data.file_type.map((item) => `.${item}`)
          .join(',');
      })
      .catch((e) => console.warn(e));

    if (!this.edit) {
      this.setDefaultValues();
    }
  },
  mounted() {
    // fill quill after it appears in DOM
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue)
        .forEach((key) => {
          this.handleChange(key, this.defaultValue[key]);
        });
    },
    handleFileNameChange(filename) {
      if (filename && !this.value.filename) {
        const fileParts = filename.split('.');
        fileParts.pop();
        this.handleChange('filename', fileParts.join('.'));
      }
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
  },
};

</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
