<template>
  <div>
    <div data-test="content-rate-question">
      {{ meta.question }}
    </div>
    <div class="tt-black--text text--lighten-2">
      <span
        v-if="meta.has_comment"
        data-test="content-rate-has-comment"
      >Можно комментировать. </span>
      <span
        v-if="meta.is_anonymous"
        data-test="content-rate-is-anonymous"
      >Анонимно. </span>
      <span data-test="content-scale-type">{{ scaleType }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentRate',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
    scaleType() {
      if (this.meta.type === 'smile') {
        return 'Смайлы';
      }
      return `Шкала из ${this.meta.validate.max} цифр`;
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
