<template>
  <div>
    <div data-test="content-video-name">
      {{ meta.name }}
    </div>
    <div
      data-test="content-video-description"
      class="tt-black--text text--lighten-2"
    >
      {{ meta.description }}
    </div>
    <a
      v-if="isLink"
      :href="meta.url"
      target="_blank"
      class="text-decoration-none"
      data-test="content-video-link-url"
    >
      {{ meta.url }}
    </a>
    <template v-else>
      {{ meta.url }}
    </template>
  </div>
</template>

<script>
export default {
  name: 'ContentVideo',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
    isLink() {
      return /^http/.test(this.meta?.url);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
