<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <TTTextField
          :value="value.question"
          large
          label="Вопрос"
          placeholder="Сформулируйте вопрос"
          :error="validation('question').status"
          :error-messages="validation('question').value"
          data-test-label="content-vote-form-question"
          @input="v => handleChange('question',v)"
        />
      </VCol>
      <VCol cols="12">
        <VCheckbox
          :ripple="false"
          class="my-0"
          color="tt-black"
          :input-value="value.is_multi"
          :error="validation('is_multi').status"
          :error-messages="validation('is_multi').value"
          label="Возможность выбора нескольких вариантов"
          hide-details="auto"
          data-test="content-vote-form-is_multi"
          @change="v => handleChange('is_multi',v)"
        />
        <TTTextField
          v-if="value.is_multi"
          class="mt-4"
          :value="value.multi_comment"
          large
          placeholder="Комментарий в случае выбора нескольких"
          :error="validation('multi_comment').status"
          :error-messages="validation('multi_comment').value"
          data-test-label="content-vote-form-multi_comment"
          @change="v => handleChange('multi_comment',v)"
        />
      </VCol>
      <VCol cols="12">
        <VDivider />
      </VCol>
      <VCol class="pb-0">
        <h2>
          Варианты ответов
        </h2>
        <div class="tt-black--text text--lighten-2">
          Минимум 2 вариант ответа
        </div>
      </VCol>
      <VCol
        cols="12"
      >
        <Draggable
          v-model="answers"
          handle=".handle"
          v-bind="dragOptions"
          data-test="content-vote-form-draggable"
          @start="drag = true"
          @end="drag = false"
          @update="reorder"
        >
          <TransitionGroup
            type="transition"
            :name="drag ? 'flip-list' : null"
          >
            <VRow
              v-for="(item, i) in answers"
              :key="i"
              align="start"
            >
              <VCol
                class="flex-grow-0 handle mt-10"
              >
                <VIcon>fal fa-grip-lines</VIcon>
              </VCol>
              <VCol>
                <TTTextarea
                  v-model="answers[i].label"
                  :maxlength="10000"
                  auto-grow
                  :label="`Ответ ${ i + 1 } - вариант ответа`"
                  :rows="1"
                  class="mb-4"
                  hide-details="auto"
                  placeholder="Сформулируйте ответ"
                  :error="validation(`answer.${i}_label`).status"
                  :error-messages="validation(`answer.${i}_label`).value"
                  :data-test-label="`content-vote-form-answer-variant-${i}`"
                  @input="handleChange(`answer.${i}_label`, answers)"
                />
                <TTTextarea
                  v-model="answers[i].comment"
                  :maxlength="10000"
                  auto-grow
                  :label="`Ответ ${ i + 1 } - комментарий к ответу`"
                  :rows="1"
                  class="mb-3"
                  hide-details="auto"
                  placeholder="Сформулируйте комментарий к ответу"
                  :error="validation(`answer.${i}_comment`).status"
                  :error-messages="validation(`answer.${i}_comment`).value"
                  :data-test-label="`content-vote-form-comment-variant-${i}`"
                  @input="handleChange(`answer.${i}_comment`, answers)"
                />
              </VCol>
              <VCol class="flex-grow-0 mt-7">
                <TTBtn
                  icon
                  :disabled="answers.length <= 2"
                  :data-test-label="`content-vote-form-variant-remove-${i}`"
                  @click="removeAnswer(i)"
                >
                  <VIcon color="tt-black">
                    fal fa-trash-alt
                  </VIcon>
                </TTBtn>
              </VCol>
              <VCol
                v-if="i < answers.length - 1"
                cols="12"
                class="py-1 pl-14"
              >
                <VDivider />
              </VCol>
            </VRow>
          </TransitionGroup>
        </Draggable>
      </VCol>
      <VCol
        cols="12"
        class="py-0"
      >
        <TTBtn
          class="ml-10"
          depressed
          color="tt-ghost--text"
          :disabled="answers.length > 19"
          data-test-label="content-vote-form-variant-add"
          @click="addAnswer"
        >
          Добавить ответ
        </TTBtn>
      </VCol>
      <VCol
        cols="12"
        class="pa-0"
      >
        <VDivider class="my-6" />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import Draggable from 'vuedraggable';
import { validation, watchValidationStatus } from '@/services';

const defaultAnswer = { label: '', comment: '' };

export default {
  name: 'ContentVoteForm',
  components: {
    Draggable,
  },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        question: '',
        answers: [
          { ...defaultAnswer },
          { ...defaultAnswer },
        ],
        is_multi: false,
        multi_comment: '',
      },
      answers: [],
      // Draggable
      drag: false,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      },
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    }
    this.setAnswers();
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue).forEach((key) => {
        this.handleChange(key, this.defaultValue[key]);
      });
    },
    handleChange(name, value, target) {
      if (name.indexOf('answer') !== -1) {
        this.$emit('input', 'answers', value, target || name);
      } else {
        this.$emit('input', name, value, target);
      }
    },
    // ***********************************************
    setAnswers() {
      this.answers = this.value.answers.slice();
    },
    reorder(e) {
      const newArr = [];
      for (let i = 0; i < this.answers.length; i += 1) {
        newArr.push(i);
      }
      const val = newArr.splice(e.oldIndex, 1);
      newArr.splice(e.newIndex, 0, val[0]);
      this.handleChange('answers', this.answers);
      this.$emit('reorder', (i) => `answer.${i}_label`, newArr);
      this.$emit('reorder', (i) => `answer.${i}_comment`, newArr);
    },
    addAnswer() {
      this.answers.push({ ...defaultAnswer });
      this.handleChange('answers', this.answers);
    },
    removeAnswer(index) {
      const newArr = [];
      for (let i = 0; i < this.answers.length; i += 1) {
        newArr.push(i);
      }
      this.answers.splice(index, 1);
      newArr.splice(index, 1);
      this.handleChange('answers', this.answers, `answer.${index}`);
      this.$emit('reorder', (i) => `answer.${i}_label`, newArr);
      this.$emit('reorder', (i) => `answer.${i}_comment`, newArr);
    },
  },
};

</script>

<style>
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .handle {
    cursor: move;
  }

</style>
