<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol
        cols="12"
      >
        <SEditor
          ref="editor"
          height="250"
          :error="validation('content').status"
          :error-messages="validation('content').value"
          data-test-label="content-html-form-editor"
          class-h1="tt-text-title-1"
          class-h2="tt-text-title-2"
          @text-change="handleChange('content', editor.getHtml())"
        >
          <template #default="{handlers}">
            <VRow>
              <VCol>
                <div>Подстановки</div>
                <div class="tt-black--text text--lighten-2 mb-5">
                  Позволяют подставлять в текст имена, фамилии и номера телефонов сотрудников
                </div>
                <TTBtn
                  v-for="content in contents"
                  :key="content.text"
                  color="tt-secondary"
                  depressed
                  class="mr-5 mb-4"
                  :data-test-label="`content-html-form-btn-${content.value.toLowerCase().replace(/\{|\}/g, '')}`"
                  @click="handlers.insertContent(content.value)"
                >
                  {{ content.text }}
                </TTBtn>
              </VCol>
            </VRow>
          </template>
        </SEditor>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';
import SEditor from '@/components/editor/SEditor.vue';

export default {
  name: 'ContentHtmlForm',
  components: { SEditor },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        content: '',
      },
      contents: [
        { text: 'Имя', value: '{FIRST_NAME}' },
        { text: 'Фамилия', value: '{LAST_NAME}' },
        { text: 'Телефон', value: '{USER_PHONE}' },
      ],
    };
  },
  computed: {
    editor() {
      return this.$refs.editor;
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    }
  },
  mounted() {
    // fill quill after it appears in DOM
    this.editor.insertRawHtml(this.value.content);
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue).forEach((key) => {
        this.handleChange(key, this.defaultValue[key]);
      });
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
