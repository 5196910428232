<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol
        cols="12"
      >
        <TTAutocomplete
          attach
          label="Адрес"
          large
          no-rotate
          hide-no-data
          clear-icon="fas fa-times-circle"
          append-icon="fal fa-search"
          :value="value.address"
          :loading="loading"
          :items="geoArr"
          item-text="address"
          :search-input.sync="search"
          return-object
          no-filter
          placeholder="Начните вводить данные"
          :disabled="!apiLoaded"
          hide-details="auto"
          single-line
          :error="validation('address').status"
          :error-messages="validation('address').value"
          data-test-label="content-map-form-autocomplete-address"
          @input="v => handlePointChange(v)"
        />
      </VCol>
      <VCol cols="12">
        <YandexMap
          :settings="settings"
          :options="options"
          :coords="latlngToCoords(value.position)"
          :zoom="9"
          :controls="controls"
          data-test="content-map-form-yandex-map"
          @click="onClick"
          @map-was-initialized="mapLoaded"
        >
          <!--Markers-->
          <YandexMarker
            v-if="value.address"
            :coords="latlngToCoords(value.position)"
            marker-id="123"
          />
        </YandexMap>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { yandexMap as YandexMap, ymapMarker as YandexMarker, loadYmap } from 'vue-yandex-maps';
import { validation, watchValidationStatus } from '@/services';

const defaultValue = {
  address: '',
  position: {
    lng: 37.57,
    lat: 55.75,
  },
};

export default {
  name: 'ContentMapForm',
  // eslint-disable-next-line vue/no-unused-components
  components: { YandexMap, YandexMarker },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      controls: ['zoomControl', 'fullscreenControl'],
      settings: {
        apiKey: process.env.VUE_APP_YANDEX_MAP_API_KEY,
        lang: 'ru_RU',
      },
      options: {
        suppressMapOpenBlock: true,
      },
      geoArr: [],
      apiLoaded: false,
      loading: false,
      search: null,
    };
  },
  watch: {
    search(val) {
      if (val && val.length > 2) {
        this.geoSearch(val);
      } else {
        this.geoArr = [];
      }
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  methods: {
    validation,
    // ********************************************
    mapLoaded(map) {
      map.cursors.push('pointer');
      this.apiLoaded = true;
      if (!this.edit) {
        this.handlePointChange();
      } else {
        this.search = this.value.address;
      }
    },
    handlePointChange(value) {
      if (value) {
        this.$emit('input', 'address', value.address);
        this.$emit('input', 'position', value.position);
      } else {
        this.$emit('input', 'address', defaultValue.address);
        this.$emit('input', 'position', { ...defaultValue.position });
      }
    },
    onClick(e) {
      this.coords = e.get('coords');
      this.getAddress(this.coords);
    },
    latlngToCoords(obj) {
      if (obj && Object.keys(obj).length > 0) {
        return [obj.lat, obj.lng];
      }
      return [];
    },
    geoSearch(val) {
      this.loading = true;
      window.ymaps.geocode(val, { results: 10 }).then((res) => {
        const geoObjectIterator = res.geoObjects.getIterator();
        this.geoArr = [];
        let item = geoObjectIterator.getNext();
        while (Object.keys(item).length > 0) {
          const realCoords = item.geometry.getCoordinates();
          const address = item.getAddressLine();
          this.geoArr.push({
            address,
            position: {
              lat: realCoords[0],
              lng: realCoords[1],
            },
          });
          item = geoObjectIterator.getNext();
        }
        this.loading = false;
      });
    },
    getAddress(coords) {
      window.ymaps.geocode(coords)
        .then((res) => {
          const firstGeoObject = res.geoObjects.get(0);
          const address = firstGeoObject.getAddressLine();
          this.handlePointChange({
            address,
            position: {
              lat: coords[0],
              lng: coords[1],
            },
          });
          this.search = address;
        });
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
  .ymap-container {
    height: 500px;
  }
</style>
