<template>
  <VContainer
    fluid
    class="px-0 select-content"
  >
    <template v-for="contentGroup in contentList">
      <VRow
        v-if="contentGroup.check"
        :key="contentGroup.title"
        class="select-content__row align-baseline my-0"
      >
        <VCol
          class="select-content__title"
        >
          {{ contentGroup.title }}
        </VCol>
        <VCol
          class="select-content__content pl-0 ml-3"
        >
          <VRow class="my-0">
            <template v-for="content in contentGroup.content">
              <VCol
                v-if="!!typeMap[content.value]"
                :key="content.value"
                class="flex-grow-0"
                :data-test="`select-content-form-button-${content.value}`"
              >
                <div
                  class="select-content__item d-flex align-center cursor--pointer"
                  @click="$emit('select', content.value)"
                >
                  <VIcon
                    size="19"
                    color="tt-black"
                    class="mr-2"
                  >
                    {{ content.icon }}
                  </VIcon>
                  {{ content.text }}
                </div>
              </VCol>
            </template>
          </VRow>
        </VCol>
      </VRow>
    </template>
  </VContainer>
</template>

<script>
export default {
  name: 'SelectContentForm',
  props: {
    typeMap: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasHTML() {
      return !!this.typeMap.HTML;
    },
    hasContent() {
      return !!this.typeMap.IMAGE || !!this.typeMap.VIDEO || !!this.typeMap.DOCUMENT;
    },
    hasQuestions() {
      return !!this.typeMap.YES_NO
        || !!this.typeMap.SELECT_ONE
        || !!this.typeMap.SELECT_MANY
        || !!this.typeMap.VOTE
        || !!this.typeMap.RATE;
    },
    hasContacts() {
      return !!this.typeMap.CONTACT || !!this.typeMap.MAP;
    },
    hasInfo() {
      return !!this.typeMap.ENTER_STRING
        || !!this.typeMap.ONE_WORD
        || !!this.typeMap.ADD_PHOTO
        || !!this.typeMap.QR_CODE;
    },
    hasOffer() {
      return !!this.typeMap.OFFER;
    },
    contentList() {
      return [
        {
          title: 'HTML',
          check: this.hasHTML,
          content: [
            {
              text: 'Блок HTML',
              value: 'HTML',
              icon: 'fal fa-code',
            },
          ],
        },
        {
          title: 'Добавить контент',
          check: this.hasContent,
          content: [
            {
              text: 'Изображение',
              value: 'IMAGE',
              icon: 'fal fa-file-image',
            },
            {
              text: 'Видео',
              value: 'VIDEO',
              icon: 'fal fa-video',
            },
            {
              text: 'Документ',
              value: 'DOCUMENT',
              icon: 'fal fa-file-alt',
            },
          ],
        },
        {
          title: 'Вопросы и голосования',
          check: this.hasQuestions,
          content: [
            {
              text: 'Вопрос да/нет',
              value: 'YES_NO',
              icon: 'fal fa-chart-pie-alt',
            },
            {
              text: 'Вопрос с одним вариантом ответа',
              value: 'SELECT_ONE',
              icon: 'fal fa-clipboard-check',
            },
            {
              text: 'Вопрос с несколькими вариантами ответа',
              value: 'SELECT_MANY',
              icon: 'fal fa-clipboard-list-check',
            },
            {
              text: 'Голосование',
              value: 'VOTE',
              icon: 'fal fa-chart-bar',
            },
            {
              text: 'Оценить по шкале',
              value: 'RATE',
              icon: 'fal fa-smile',
            },
          ],
        },
        {
          title: 'Контакты',
          check: this.hasContacts,
          content: [
            {
              text: 'Контакт сотрудника',
              value: 'CONTACT',
              icon: 'fal fa-user-tie',
            },
            {
              text: 'Карта',
              value: 'MAP',
              icon: 'fal fa-map-marker-alt',
            },
          ],
        },
        {
          title: 'Ввод информации',
          check: this.hasInfo,
          content: [
            {
              text: 'Ввод строки',
              value: 'ENTER_STRING',
              icon: 'fal fa-keyboard',
            },
            {
              text: 'Кодовое слово',
              value: 'ONE_WORD',
              icon: 'fal fa-comment',
            },
            {
              text: 'Сделать фото',
              value: 'ADD_PHOTO',
              icon: 'fal fa-camera',
            },
            {
              text: 'QR-код',
              value: 'QR_CODE',
              icon: 'fal fa-qrcode',
            },
          ],
        },
        {
          title: 'Предложение о работе',
          check: this.hasOffer,
          content: [
            {
              text: 'Предложение о работе',
              value: 'OFFER',
              icon: 'fal fa-user-check',
            },
          ],
        },
      ];
    },
  },
};

</script>

<style>
.select-content .select-content__row:last-child,
.select-content .select-content__row:not(:last-child) .select-content__content {
  border-bottom: 1px solid #EBECED;
}

.select-content .select-content__item {
  padding: 12px 16px;
  background: #FFFFFF;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08);
  border-radius: 5px;
  white-space: nowrap;
  line-height: 19px;
  letter-spacing: 0.61px;
}
.select-content .select-content__title{
  max-width: 200px;
  width: 200px;
  line-height: 19px;
  letter-spacing: 0.61px;
}

</style>
