<template>
  <VContainer class="px-0">
    <VRow
      dense
      data-test="task-type-question"
    >
      <VCol data-test="content-yes-no-question">
        {{ meta.question }}
      </VCol>
    </VRow>
    <div
      v-if="showItemInfo"
    >
      <VRow
        v-if="meta.action.yes_text"
        dense
      >
        <VCol
          class="tt-black--text text--lighten-2 text-right"
          cols="3"
          data-test="content-yes-title"
        >
          Да
        </VCol>
        <VCol data-test="content-yes-text">
          {{ meta.action.yes_text }}
        </VCol>
      </VRow>
      <VRow
        v-if="meta.action.no_text"
        dense
      >
        <VCol
          class="tt-black--text text--lighten-2 text-right"
          cols="3"
          data-test="content-no-title"
        >
          Нет
        </VCol>
        <VCol data-test="content-no-text">
          {{ meta.action.no_text }}
        </VCol>
      </VRow>
    </div>
  </VContainer>
</template>

<script>
export default {
  name: 'ContentYesNo',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
    showItemInfo() {
      return this.meta.action.yes_text || this.meta.action.no_text;
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
