<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="12">
        <TTTextField
          large
          label="Название"
          placeholder="Введите название"
          :value="value.name"
          :error="validation('name').status"
          :error-messages="validation('name').value"
          data-test-label="content-video-form-name"
          @change="v => handleChange('name',v)"
        />
      </VCol>
      <VCol
        cols="12"
      >
        <TTTextarea
          :maxlength="10000"
          auto-grow
          :rows="4"
          row-height="14"
          label="Описание"
          placeholder="Введите текст"
          hide-details="auto"
          :value="value.description"
          :error="validation('description').status"
          :error-messages="validation('description').value"
          data-test-label="content-video-form-description"
          @input="v => handleChange('description',v)"
        />
      </VCol>
      <VCol
        cols="12"
      >
        <div class="pb-3 tt-text-body-2">
          Выберите один из вариантов загрузки видео
        </div>
        <!--        Табы с правильными стилями, при выносе в обертку взять пример отсюда-->
        <VBtnToggle
          v-model="type"
          mandatory
          background-color="tt-light-mono-4"
          active-class="tt-secondary-danger"
          @change="handleChangeType"
        >
          <VBtn
            :value="TYPES.URL"
            large
            class="tt-light-mono-46--text"
            data-test-label="tab-insert-video-link"
          >
            Вставить ссылку на видео
          </VBtn>
          <VBtn
            :value="TYPES.UPLOADER"
            large
            class="tt-light-mono-46--text"
            data-test-label="tab-upload-video"
          >
            Загрузить видео
          </VBtn>
        </VBtnToggle>
      </VCol>
      <VCol
        v-if="TYPES.UPLOADER === type"
        cols="12"
      >
        <div class="pb-3 tt-text-body-2">
          Видео
        </div>
        <Uploader
          :key="keyPlayer"
          ref="Uploader"
          type="VIDEO"
          :value="value.url"
          :max-file-mb="500"
          :error="validation('url').status"
          :error-messages="validation('url').value"
          :req-params-video="{
            description : '',
            location_id : locationId
          }"
          @input="(v) => handleChange('url', v)"
        />
      </VCol>
      <VCol
        v-if="TYPES.UPLOADER === type && value.url"
        cols="12"
      >
        <VRow
          dense
          align="center"
          class="pb-3"
        >
          <VCol cols="auto">
            <div class="tt-text-body-2">
              Обложка
            </div>
          </VCol>
          <VCol cols="auto">
            <VMenu
              right
              nudge-right="24"
              :attach="true"
              max-width="280"
              content-class="tt-light-mono-100--text tt-text-body-2 shadow"
            >
              <template #activator="{ on, attrs }">
                <VIcon
                  data-test="content-video-poster-help-tooltip"
                  size="19"
                  v-bind="attrs"
                  color="primary"
                  v-on="on"
                >
                  fal fa-question-circle
                </VIcon>
              </template>
              <template #default>
                <CardWrapper
                  min-height="auto"
                  class="pa-4"
                  data-test="content-video-poster-hint-tooltip-container"
                >
                  Поле не обязательное. Если постер не загружен, будет использован первый кадр видео
                </CardWrapper>
              </template>
            </VMenu>
          </VCol>
        </VRow>
        <Uploader
          type="IMAGE"
          :value="poster"
          :max-file-mb="8"
          :error="!!errorPoster"
          :error-messages="errorPoster"
          :show-loading="loadingPoster"
          :abort="false"
          :progress-indeterminate="true"
          @selectFile="(file) => uploadPoster(file)"
          @delete="deletePoster"
        />
      </VCol>
      <VCol
        v-if="type === TYPES.URL"
        cols="12"
      >
        <TTTextField
          large
          label="URL"
          placeholder="Введите ссылку на видео"
          :value="value.url"
          :error="validation('url').status"
          :error-messages="validation('url').value"
          data-test-label="content-video-form-url"
          @change="v => handleChange('url',v)"
        />
      </VCol>
      <VCol cols="12">
        <VDivider />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';
import Uploader from '@/components/ui/Uploader.vue';
import * as snamiApi from '@/services/backend/snami';
import CardWrapper from '@/components/shared/CardWrapper.vue';

const TYPES = {
  URL: 'URL',
  UPLOADER: 'UPLOADER',
};
export default {
  name: 'ContentVideoForm',
  components: {
    CardWrapper,
    Uploader,
  },
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showTooltip: false,
      defaultValue: {
        url: '',
        name: '',
        description: '',
        screenshot: {
          virgin_url: '',
        },
      },
      cacheUrl: {},
      type: TYPES.URL,
      TYPES,
      errorDelete: null,
      errorPoster: null,
      loadingPoster: false,
      poster: '',
      keyPlayer: 0,
    };
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
    'value.screenshot.virgin_url': {
      immediate: true,
      handler(url) {
        this.poster = url ?? '';
      },
    },
    'value.url': {
      handler(url) {
        if (!url) {
          this.poster = '';
        }
      },
    },
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    } else if (!this.value?.url.includes('http')) {
      // При редактировании если в url нет http, значит это video id, выставляем таб загрузки видео
      this.type = TYPES.UPLOADER;
    }
  },
  mounted() {
    this.$root.$on('modalTaskContentFormWillClose', this.onModalWillClose);
  },
  beforeDestroy() {
    this.$root.$off('modalTaskContentFormWillClose', this.onModalWillClose);
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue)
        .forEach((key) => {
          this.handleChange(key, this.defaultValue[key]);
        });
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
    handleChangeType(type) {
      const prevType = type === TYPES.URL ? TYPES.UPLOADER : TYPES.URL;
      this.cacheUrl[prevType] = this.value.url;
      this.handleChange('url', this.cacheUrl[type] ?? '');
    },
    onModalWillClose(d) {
      // производим удаление видео если модальное окно будет закрыто, сохранение не происходило
      if (!this.edit
        && typeof d?.wasSaved !== 'undefined'
        && !d?.wasSaved) {
        if (this.type === TYPES.UPLOADER && this.value.url.length) {
          try {
            this.$refs.Uploader.onDelete();
          } catch (e) {
            console.error(e);
          }
        }
      }
    },
    async uploadPoster(file) {
      this.errorPoster = null;
      if (file) {
        try {
          this.loadingPoster = true;
          const { data } = await snamiApi.videoPoster(this.value.url, file, file?.type);
          this.poster = data?.url;
          this.handleChange('screenshot', { virgin_url: this.poster });
        } catch (e) {
          this.errorPoster = 'Не удалось загрузить файл';
          console.error(e);
        } finally {
          this.keyPlayer += 1;
          this.loadingPoster = false;
        }
      }
    },
    async deletePoster() {
      this.errorPoster = null;
      try {
        await snamiApi.videoDeletePoster(this.value.url);
        this.poster = '';
        this.handleChange('screenshot', { virgin_url: '' });
      } catch (e) {
        if (e?.request?.status === 400) {
          this.poster = '';
        } else {
          this.errorPoster = 'Не удалось удалить файл';
        }
      } finally {
        this.keyPlayer += 1;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
</style>
