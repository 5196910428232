<template>
  <VContainer
    fluid
    class="pa-3"
  >
    <VRow class="my-0">
      <VCol
        cols="1"
        class="text-center"
      >
        <div
          v-if="step === 2 && !edit"
          class="aside-panel"
        >
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test-label="task-content-form-btn-back"
              @click="rollBack"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-arrow-left
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Назад
            </div>
          </div>
        </div>
      </VCol>
      <VCol cols="10">
        <VRow class="my-0">
          <VCol
            lg="9"
            sm="12"
          >
            <div class="d-flex align-center">
              <h1 class="tt-text-headline-1">
                {{ formTitle }}
              </h1>
              <div
                v-if="loading"
                class="pl-4"
              >
                <VProgressCircular
                  indeterminate
                  color="primary"
                />
              </div>
            </div>
            <div
              v-if="globalErrorMessage"
              class="error--text mt-2"
            >
              {{ globalErrorMessage }}
            </div>
            <VWindow
              v-if="!loading"
              v-model="step"
            >
              <VWindowItem :value="1">
                <SelectContentForm
                  :type-map="typeMap"
                  @select="handleTypeSelect($event)"
                />
              </VWindowItem>
              <VWindowItem :value="2">
                <VRow
                  v-if="entity.type"
                  class="my-0"
                >
                  <VCol cols="12">
                    <Component
                      :is="componentsMap[entity.type]"
                      v-if="step === 2"
                      :value="meta"
                      :location-id="locationId"
                      :validation-status="metaValidationStatus"
                      :edit="edit"
                      :data-test="`task-content-form-component-${entity.type}`"
                      @input="handleMetaChange"
                      @reorder="handleMetaReorder"
                    />
                  </VCol>
                  <VCol
                    v-if="callbackMap[entity.type]"
                    cols="12"
                  >
                    <VCheckbox
                      v-for="cb in callbackMap[entity.type]"
                      :key="cb.name"
                      v-model="callback"
                      :label="cb.label"
                      :value="cb.name"
                      hide-details="auto"
                      single-line
                      color="tt-black"
                      class="mt-0 pt-0"
                      :ripple="false"
                      :data-test="`task-content-form-canskip-${cb.name}-${entity.type}`"
                    />
                  </VCol>
                  <VCol
                    v-if="typeMap[entity.type].can_be_skipped"
                    cols="12"
                  >
                    <VCheckbox
                      v-model="entity.can_be_skipped"
                      label="Можно пропустить"
                      hide-details="auto"
                      color="tt-black"
                      class="mt-0 pt-0"
                      :ripple="false"
                      :data-test="`task-content-form-canskip-${entity.type}`"
                    />
                  </VCol>
                  <template v-if="levelN !== 0">
                    <VCol
                      v-if="typeMap[entity.type].has_right_bonus"
                      cols="6"
                    >
                      <TTTextField
                        v-mask="'#####'"
                        hide-details="auto"
                        large
                        :label="typeMap[entity.type].has_wrong_bonus ? 'Бонус за правильный ответ' : 'Бонус'"
                        placeholder="Введите количество"
                        :value="entity.bonus || ''"
                        :error="validation('bonus').status"
                        :error-messages="validation('bonus').value"
                        :data-test-label="`task-content-form-bonus-success-${entity.type}`"
                        @input="v => handleChange('bonus', parseInt(v, 10))"
                      />
                    </VCol>
                    <VCol
                      v-if="typeMap[entity.type].has_wrong_bonus"
                      cols="6"
                    >
                      <TTTextField
                        v-mask="'#####'"
                        large
                        :label="typeMap[entity.type].has_right_bonus ? 'Бонус за ошибку' : 'Бонус'"
                        placeholder="Введите количество"
                        :value="entity.bonus_wrong || ''"
                        :error="validation('bonus_wrong').status"
                        :error-messages="validation('bonus_wrong').value"
                        :data-test-label="`task-content-form-bonus-error-${entity.type}`"
                        @input="v => handleChange('bonus_wrong', parseInt(v, 10))"
                      />
                    </VCol>
                  </template>

                  <VCol
                    cols="12"
                  >
                    <TTBtn
                      large
                      color="tt-secondary"
                      class="mr-6"
                      depressed
                      :data-test-label="`task-content-form-cancel-${entity.type}`"
                      @click="() => handleClose()"
                    >
                      Отменить
                    </TTBtn>
                    <TTBtn
                      depressed
                      large
                      :loading="saving"
                      :disabled="saving"
                      :data-test-label="`task-content-form-submit-${entity.type}`"
                      retain-focus-on-click
                      @click="save"
                    >
                      {{ entity.id > 0 ? 'Сохранить' : 'Добавить элемент' }}
                    </TTBtn>
                  </VCol>
                </VRow>
              </VWindowItem>
            </VWindow>
          </VCol>
        </VRow>
      </VCol>
      <VCol cols="1">
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              :data-test-label="`task-content-form-close-${entity.type}`"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import set from 'lodash/set';
import { mask } from 'vue-the-mask';
import cloneDeep from 'lodash/cloneDeep';
import { handleError, validation, watchValidationStatus } from '@/services';
import { contentTypeText, dictionaryKeys } from '@/constants';

import SelectContentForm from '@/components/forms/contentForms/SelectContentForm.vue';
import ContentHtmlForm from '@/components/forms/contentForms/ContentHtmlForm.vue';
import ContentContactForm from '@/components/forms/contentForms/ContentContactForm.vue';
import ContentVideoForm from '@/components/forms/contentForms/ContentVideoForm.vue';
import ContentOfferForm from '@/components/forms/contentForms/ContentOfferForm.vue';
import ContentQrCodeForm from '@/components/forms/contentForms/ContentQrCodeForm.vue';
import ContentOneWordForm from '@/components/forms/contentForms/ContentOneWordForm.vue';
import ContentYesNoForm from '@/components/forms/contentForms/ContentYesNoForm.vue';
import ContentEnterStringForm from '@/components/forms/contentForms/ContentEnterStringForm.vue';
import ContentImageForm from '@/components/forms/contentForms/ContentImageForm.vue';
import ContentDocumentForm from '@/components/forms/contentForms/ContentDocumentForm.vue';
import ContentSelectOneForm from '@/components/forms/contentForms/ContentSelectOneForm.vue';
import ContentSelectManyForm from '@/components/forms/contentForms/ContentSelectManyForm.vue';
import ContentAddPhotoForm from '@/components/forms/contentForms/ContentAddPhotoForm.vue';
import ContentVoteForm from '@/components/forms/contentForms/ContentVoteForm.vue';
import ContentRateForm from '@/components/forms/contentForms/ContentRateForm.vue';
import ContentMapForm from '@/components/forms/contentForms/ContentMapForm.vue';

const defaultEntity = {
  id: 0,
  type: '',
  meta: {},
  callback: [],
  bonus: 0,
  bonus_wrong: 0,
  can_be_skipped: false,
};

export default {
  name: 'TaskContentForm',
  components: {
    SelectContentForm,
    ContentHtmlForm,
    ContentContactForm,
    ContentVideoForm,
    ContentOfferForm,
    ContentQrCodeForm,
    ContentOneWordForm,
    ContentYesNoForm,
    ContentEnterStringForm,
    ContentImageForm,
    ContentDocumentForm,
    ContentSelectOneForm,
    ContentSelectManyForm,
    ContentAddPhotoForm,
    ContentVoteForm,
    ContentRateForm,
    ContentMapForm,
  },
  directives: { mask },
  props: {
    editEntity: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    levelN: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      step: 1,
      saving: false,
      loading: false,
      validationStatus: [],
      globalErrorMessage: '',
      metaValidationStatus: [],
      entity: { ...defaultEntity },
      meta: {},
      contentTypeList: [],
      contentTypeMap: {},
      eventContentTypeList: [],
      eventContentTypeMap: {},
      chatContentTypeList: [],
      chatContentTypeMap: {},
      contentCallbackMap: {},
      eventCallbackMap: {},
      chatCallbackMap: {},
      callback: [],
      componentsMap: {
        HTML: 'ContentHtmlForm',
        IMAGE: 'ContentImageForm',
        DOCUMENT: 'ContentDocumentForm',
        QR_CODE: 'ContentQrCodeForm',
        VIDEO: 'ContentVideoForm',
        MAP: 'ContentMapForm',
        ENTER_STRING: 'ContentEnterStringForm',
        ADD_PHOTO: 'ContentAddPhotoForm',
        YES_NO: 'ContentYesNoForm',
        ONE_WORD: 'ContentOneWordForm',
        SELECT_ONE: 'ContentSelectOneForm',
        SELECT_MANY: 'ContentSelectManyForm',
        VOTE: 'ContentVoteForm',
        RATE: 'ContentRateForm',
        OFFER: 'ContentOfferForm',
        CONTACT: 'ContentContactForm',
      },
      contentTypeTextMap: contentTypeText,

    };
  },
  computed: {
    formTitle() {
      if (this.step === 1) return 'Выберите тип элемента';
      return this.contentTypeTextMap[this.entity.type];
    },
    taskId() {
      return this.task.id;
    },
    locationId() {
      return this.location.id;
    },
    typeList() {
      if (this.task.level_id) {
        if (this.levelN === 0) {
          return this.chatContentTypeList;
        }
        return this.contentTypeList;
      }
      return this.eventContentTypeList;
    },
    typeMap() {
      if (this.task.level_id) {
        if (this.levelN === 0) {
          return this.chatContentTypeMap;
        }
        return this.contentTypeMap;
      }
      return this.eventContentTypeMap;
    },
    callbackMap() {
      if (this.task.level_id) {
        if (this.levelN === 0) {
          return this.chatCallbackMap;
        }
        return this.contentCallbackMap;
      }
      return this.eventCallbackMap;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'entity.type': function (val) {
      if (!this.edit) {
        this.meta = {};
        this.validationStatus = [];
        this.entity.can_be_skipped = this.contentTypeMap[val]?.default_can_be_skipped ?? false;
      }
    },
    callback(val) {
      this.entity.callback = val.map((item) => ({
        type: item,
      }));
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.loading = true;
    this.$repositories.customer.getCustomerDictionary({
      data: {
        key: [
          dictionaryKeys.eventContentType,
          dictionaryKeys.levelContentType,
          dictionaryKeys.chatContentType,
        ],
      },
    })
      .then((r) => {
        const { data } = r.data;
        this.eventContentTypeList = data.event_content_type
          .map((item) => {
            if (item.callback.length) {
              this.eventCallbackMap[item.name] = item.callback;
            }
            this.eventContentTypeMap[item.name] = item;
            return {
              value: item.name,
              text: item.label,
            };
          });
        this.chatContentTypeList = data.chat_content_type
          .map((item) => {
            if (item.callback.length) {
              this.chatCallbackMap[item.name] = item.callback;
            }
            this.chatContentTypeMap[item.name] = item;
            return {
              value: item.name,
              text: item.label,
            };
          });
        this.contentTypeList = data.level_content_type
          .map((item) => {
            if (item.callback.length) {
              this.contentCallbackMap[item.name] = item.callback;
            }
            this.contentTypeMap[item.name] = item;
            return {
              value: item.name,
              text: item.label,
            };
          });
      })
      .catch((e) => {
        console.warn(e);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  mounted() {
    if (this.edit) {
      this.step = 2;
      this.handleEntity();
    }
  },

  methods: {
    validation,
    handleError,
    rollBack() {
      // Ужасный костыль, нужно разобраться почему не отрабатывает
      // свойство "retain-focus-on-click" на кнопках
      const dialog = document.getElementById('dialogWrapper').children[0];
      dialog.tabIndex = '-1';
      dialog.focus();
      this.step = 1;
      this.validationStatus = [];
      this.metaValidationStatus = [];
      this.callback = [];
      this.globalErrorMessage = '';
      this.entity = { ...defaultEntity };
    },
    async save() {
      try {
        const data = {
          ...this.entity,
          task_id: this.taskId,
        };
        const meta = { ...this.meta };
        const error = [];
        if (data.type === 'RATE') {
          const splittedType = meta.typeMax.split(':');
          // eslint-disable-next-line prefer-destructuring
          meta.type = splittedType[0];
          meta.validate.max = parseInt(splittedType[1], 10);
          delete meta.typeMax;
        }
        if (data.type === 'SELECT_ONE'
          || data.type === 'SELECT_MANY'
          || data.type === 'VOTE') {
          meta.answers.forEach((item, i) => {
            if (!item.label) {
              error.push({
                key: data.type === 'VOTE' ? `answer.${i}_label` : `answer.${i}`,
                value: 'Обязательное поле',
              });
            }
          });
        }

        if (error.length) {
          this.metaValidationStatus = error;
          return;
        }

        data.meta = JSON.stringify(meta);
        this.saving = true;
        if (this.edit) {
          await this.$repositories.taskContent.update({ data });
        } else {
          await this.$repositories.taskContent.create({ data });
        }
        this.$emit('save');
      } catch (e) {
        this.metaValidationStatus = [];
        this.handleError(e);
        const { response } = e;
        this.validationStatus.forEach((item) => {
          if (item.key.indexOf(this.entity.type) !== -1) {
            const contentItem = { ...item };
            contentItem.key = item.key.slice(this.entity.type.length + 1);
            contentItem.key = contentItem.key.replace('_', '.');
            this.metaValidationStatus.push(contentItem);
          } else {
            this.metaValidationStatus.push(item);
          }
        });
        // Глобальные ошибки
        if (!response.data.error.data) {
          this.globalErrorMessage = response.data.error.message || '';
        }
      } finally {
        this.saving = false;
      }
    },
    handleEntity() {
      // Инициализация entity
      if (this.edit) {
        const meta = JSON.parse(this.editEntity.meta);
        this.entity = { ...this.editEntity };
        this.callback = this.editEntity.callback.map((item) => item.type);
        if (this.entity.type === 'RATE') {
          meta.typeMax = `${meta.type}:${meta.validate.max}`;
        }
        this.meta = meta;
      }
    },
    handleTypeSelect(type) {
      this.entity.type = type;
      this.step = 2;
    },
    handleClose() {
      this.$emit('close');
    },
    handleChange(name, value, target) {
      const key = target || name;
      this.entity[name] = value;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
      this.globalErrorMessage = '';
    },
    handleMetaChange(name, value, target) {
      const key = target || name;
      set(this.meta, name, value);
      this.metaValidationStatus = this.metaValidationStatus.filter((i) => i.key !== key);
      this.globalErrorMessage = '';
    },
    handleMetaReorder(name, newArr) {
      const metaValidationStatus = cloneDeep(this.metaValidationStatus);
      newArr.forEach((key, i) => {
        const index = this.metaValidationStatus.findIndex((item) => item.key === `${name(key)}`);
        if (index !== -1) {
          metaValidationStatus[index].key = `${name(i)}`;
        }
      });
      this.metaValidationStatus = metaValidationStatus;
    },
  },
};

</script>

<style scoped lang="scss">
::v-deep .v-label {
  font-size: 14px;
}
</style>
