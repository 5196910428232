<template>
  <VContainer
    fluid
    class="pa-0"
  >
    <VRow>
      <VCol cols="6">
        <TTSelect
          large
          :attach="false"
          label="Тип пользователя"
          placeholder="Выберете тип пользователя"
          :value="value.type"
          :items="userTypeList"
          :error="validation('type').status"
          :error-messages="validation('type').value"
          data-test-label="content-contact-form-type"
          @input="v => handleChange('type',v)"
        />
      </VCol>
      <VCol
        v-if="value.type === roles.STAFF"
        cols="6"
      >
        <TTAutocomplete
          :menu-props="{ maxHeight : 175 }"
          no-rotate
          large
          attach
          label="Сотрудник"
          append-icon="fal fa-search"
          placeholder="Поиск по ФИО"
          :value="value.id"
          :loading="loading"
          :items="staff"
          :search-input.sync="search"
          item-value="id"
          :item-text="fullName"
          hide-no-data
          hide-details="auto"
          single-line
          :error="validation('content_id').status"
          :error-messages="validation('content_id').value"
          data-test-label="content-contact-form-content_id"
          @input="v => handleChange('id',v, 'content_id')"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { validation, watchValidationStatus } from '@/services';
import { roles } from '@/constants';

export default {
  name: 'ContentContactForm',
  props: {
    validationStatus: {
      type: Array,
      default: () => ([]),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      defaultValue: {
        type: '',
        id: 0,
      },

      loading: false,
      search: null,
      roles,
      staff: [],
      userTypeList: [
        { text: 'Руководитель', value: roles.MENTOR },
        { text: 'Наставник', value: roles.TUTOR },
        { text: 'HR', value: roles.HR },
        { text: 'Сотрудник', value: roles.STAFF },
      ],
    };
  },
  watch: {
    search(val) {
      if (val) {
        this.querySelections(val);
      } else {
        this.staff = [];
        this.$emit('input', 'id', 0);
      }
    },
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    if (!this.edit) {
      this.setDefaultValues();
    } else {
      this.querySelections();
    }
  },
  methods: {
    validation,
    setDefaultValues() {
      Object.keys(this.defaultValue).forEach((key) => {
        this.handleChange(key, this.defaultValue[key]);
      });
    },
    querySelections(query) {
      this.loading = true;
      const filter = { location_id: null, is_fired: false };
      if (query) {
        filter.search_text = query;
      } else if (this.value.id) {
        filter.id = [this.value.id];
      }
      this.$repositories.staff.list({ data: { filter, page_size: 20 } }).then((r) => {
        const { data } = r.data;
        this.staff = data;
        this.loading = false;
      }).catch((e) => console.warn(e));
    },
    fullName(item) {
      return `${item.last_name} ${item.first_name}`;
    },
    handleChange(name, value, target) {
      this.$emit('input', name, value, target);
    },
  },
};

</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
