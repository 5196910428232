<template>
  <VContainer class="px-0">
    <VRow dense>
      <VCol data-test="content-one-word-question">
        {{ meta.question }}
      </VCol>
    </VRow>
    <VRow dense>
      <VCol
        class="tt-black--text text--lighten-2 text-right"
        cols="3"
        data-test="content-one-word-title-word"
      >
        Кодовое слово
      </VCol>
      <VCol data-test="content-one-word-validate-text">
        {{ meta.validate.text }}
      </VCol>
    </VRow>
    <VRow
      v-if="hasActionRight"
      dense
    >
      <VCol
        class="tt-black--text text--lighten-2 text-right"
        cols="3"
        data-test="content-one-word-title-correct"
      >
        Правильно
      </VCol>
      <VCol data-test="content-one-word-action-right">
        {{ meta.action.right }}
      </VCol>
    </VRow>
    <VRow
      v-if="hasActionWrong"
      dense
    >
      <VCol
        class="tt-black--text text--lighten-2 text-right"
        cols="3"
        data-test="content-one-word-title-incorrect"
      >
        Неправильно
      </VCol>
      <VCol data-test="content-one-word-action-wrong">
        {{ meta.action.wrong }}
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'ContentOneWord',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
    hasActionRight() {
      return this.meta?.action?.right;
    },
    hasActionWrong() {
      return this.meta?.action?.wrong;
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
