<template>
  <div class="upload-file">
    <div class="d-flex align-center">
      <div
        v-if="!fileName"
        class="mr-6"
      >
        <TTBtn
          :loading="loading"
          :disabled="loading"
          color="tt-secondary"
          large
          fab
          data-test-label="upload-file-btn"
          @click="onClick"
        >
          <VIcon>fal fa-plus</VIcon>
        </TTBtn>
      </div>
      <div>
        <div>Загрузите файл</div>
        <div
          v-if="!fileName"
          class="tt-black--text text--lighten-2"
        >
          Просмотр в приложении доступен только для документов формата pdf,
          остальные файлы для просмотра необходимо загрузить в смартфон.
        </div>
        <div
          v-else
          class="mt-3 "
        >
          <VChip
            close
            label
            class="upload-file__chips"
            @click:close="reset"
          >
            {{ truncateString(fileName, 20) }}
          </VChip>
        </div>
      </div>
    </div>
    <div
      v-if="errorMsg || errorMessages"
      class="upload-file__error mt-3"
    >
      {{ errorMsg || errorMessages }}
    </div>
    <input
      v-show="false"
      ref="input"
      :accept="accept"
      type="file"
      @input="onInput"
    >
  </div>
</template>
<script>
import { fileToBase64, truncateString } from '@/utils';

export default {
  name: 'UploadFile',
  props: {
    value: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
    accept: {
      type: String,
      default: '*/*',
    },
  },
  data: () => ({
    loading: false,
    fileName: '',
    errorMsg: '',
  }),
  created() {
    if (this.value) {
      if (this.value.indexOf('http') !== -1) {
        // Вытаскиваем из URL имя файла
        const fileName = new URL(this.value);
        this.fileName = decodeURI(fileName.pathname.split('/').pop());
      } else {
        this.fileName = this.value;
      }
    }
  },
  methods: {
    truncateString,
    onClick() {
      this.$refs.input.click();
    },
    reset() {
      this.fileName = '';
      this.$emit('fileNameChanged', '');
      this.$emit('input', '');
    },
    async onInput(event) {
      this.loading = true;
      this.errorMsg = '';
      const { target } = event;
      if (target.files.length > 0) {
        if (target.files[0].size > 15 * 1024 * 1024) {
          this.errorMsg = 'Размер файла не должен превышать 15 Мб';
          this.loading = false;
          return;
        }
        this.fileName = target.files[0].name;
        try {
          const src = await fileToBase64(target);
          const base64 = src.substring(src.indexOf('base64,') + 7);
          this.$emit('fileNameChanged', this.fileName);
          this.$emit('input', base64);
        } catch (e) {
          console.warn(e);
          this.errorMsg = 'Ошибка загрузки файла.';
        }
      } else {
        this.reset();
      }
      this.loading = false;
    },
  },
};
</script>

<style>
  .upload-file .upload-file__error {
    color: #EF323F;
  }
  .upload-file .v-chip.upload-file__chips {
    height: 46px;
    background-color: #F5F6F6;
    border: 1px solid #EBECED;
    border-radius: 5px!important;
  }
</style>
