<template>
  <div>
    <div data-test="content-add-photo-question">
      {{ meta.question }}
    </div>
    <div
      class="tt-black--text text--lighten-2 d-flex"
      data-test="content-add-photo-photo"
    >
      {{ callbackType }}
      {{ visionTemplate }}
    </div>
  </div>
</template>

<script>
import { CallbackTypeMap } from '@/constants';

const ValidationMetaTemplateMap = {
  face: 'Шаблон «Лицо»',
};
export default {
  name: 'ContentAddPhoto',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    callbackType() {
      const callbackTypes = [];
      this.data.callback.forEach((item) => {
        callbackTypes.push(CallbackTypeMap[item.type] || item.type);
      });
      return callbackTypes.join(', ');
    },
    visionTemplate() {
      const { vision_template: visionTemplate } = this.meta.validate;
      return ValidationMetaTemplateMap[visionTemplate] || visionTemplate;
    },
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
