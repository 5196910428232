<template>
  <div>
    <div data-test="content-document-filename">
      {{ meta.filename }}
    </div>
    <div>
      <a
        :href="meta.file"
        target="_blank"
        class="text-decoration-none"
        data-test="content-document-link-download"
      >
        Скачать
      </a>
    </div>
    <div
      class="tt-black--text text--lighten-2"
      data-test="content-document-description"
    >
      {{ meta.description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentDocument',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
