<template>
  <div data-test="content-image">
    <VImg
      :src="meta.file"
      :lazy-src="meta.file"
      class="grey lighten-2 rounded"
      max-width="128"
    />
  </div>
</template>

<script>
export default {
  name: 'ContentImage',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
  /* stylelint-ignore no-empty-source */
</style>
